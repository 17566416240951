@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-section-TermsAndConditions{
    @include main-body;
    margin-left: 90px;
}
.T-C{
    margin: 20px;
    p{
    text-align: justify;
    }
}