@import "../css//variables.scss";
@import "../css/mixin.scss";


.main-body-Notification{
    @include main-body;
    margin-left: 90px
}
.notification-block{
@include common-body;
border-radius: 5px;
padding: 10px 20px;
.notifications{
color: $data-font-color;
font-size: 16px;
}
.option-menu{
    height: 24px;
    width: 20px;
}
}