@import "../css//variables.scss";
@import "../css/mixin.scss";

.contract-main-section {
 
  .contract-top-bar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000008;
    opacity: 1;
    padding: 10px 20px;
    vertical-align: middle;
  }

  .contract-top-heading {
    text-align: left;
    font-size:  16px ;
    font-weight: 600;
    letter-spacing: 0.26px;
    color: #2c3244;
    opacity: 1;
    margin: 0;
  }

  .contract-paragraph {
    padding: 20px;
  }

  .contract-rates {
    padding: 20px;
  }
}

table {
  width: 100%;
  min-width: 1200px;
  border-collapse: separate;
  border-spacing: 0 15px;
  overflow-x: auto;

  .t-row {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000007;
    border-radius: 5px;
    opacity: 1;
  }
  td {
    padding: 5px 10px;
    color: var(--unnamed-color-2c3244);
    text-align: left;
    font: normal normal normal 14px/22px Overpass;
    letter-spacing: 0.22px;
    color: #2c3244;
    opacity: 1;
  }
}
