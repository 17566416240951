.main-body-GoodsDetails {
    .data{
      font-size: 14px;
    }
    .doc-img{
      display: flex;
      img{
        width: 100%;
        height: auto;
      }
      a{
        img{
          height: 24px;
          width: 24px;
          margin-left: 5px;
        }
      }
    }
  }
  .common-body {
    background: #fdfeff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00788c0d;
    border-radius: 15px;
    opacity: 1;
    position: relative;
  }