@import '../css/mixin.scss';
@import '../css/variables.scss';


.Freights-body {
    transition: all 0.5s ease-in-out;
    margin-left: 70px;
    box-shadow: 0px 4px 16px #00000008;
 

}
