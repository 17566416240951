@import '../css/mixin.scss';
@import '../css/variables.scss';

.main-body-contactUs {
    @include main-body;

    margin-left: 90px
}

.contactUs {
    @include common-body;
    width: auto;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


}

.continue-btn {
    text-decoration: none;
    color: $btn-font-color2;
    background-color: $btn-font-color1;
    padding: 7px 30px;
    border-radius: 20px;
}

.left-paragraph {
    text-align: center;
    color: $data-font-color;
    font-size: 16px;
}


.email {
    text-align: center;
}

.left-heading {
    color: $btn-bg-color2;
    font-weight: bolder;
    text-align: center;

}