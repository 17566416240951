@import "../css//variables.scss";
@import "../css/mixin.scss";

body {
  background-color: #f0f9fc;
}

.main-section-AddVehicle {
  @include main-body;
  margin-left: 90px;
}

.main-section-AddVehicle {
  h5 {
    color: #33778e;
  }

  label {
    color: #66739b;
  }

  hr {
    height: 1px;
    color: #4f9fe6;
  }

  .main-body-AddVehicle {
    .drag-drop {
      color: #b2bad3;
      font-size: 14px;
    }
  }

  #heading-addVehicles {
    text-align: left;
    font: normal normal bold 18px Overpass;
    letter-spacing: 0.86px;
    color: #45AEEA !important;
    text-transform: uppercase;
    opacity: 1;
  }

  //   height: 100vh;
  .progress-block {
    height: auto;
    background-color: #D5EDFA;
    border-radius: 15px;

    .progress-status {
      text-align: right;
      font: normal normal 600 16px/25px Overpass;
      letter-spacing: 0.38px;
      color: #2c3244;
      opacity: 1;
    }

    .progress {
      padding: 2px;
      background-color: #f8faff;

      .progress-bar {
        background-color: #1b9be6;
      }
    }
  }

  .card-heading {
    text-align: left;
    font: normal normal 600 16px/25px Overpass;
    letter-spacing: 0.38px;
    color: #33778e;
    opacity: 1;
  }

  .main-vehicle-info {
    @include common-body;

    .border-block {
      border: 1px dashed #dddddd;
      border-radius: 10px;
      opacity: 1;

      .uploading {
        padding: 70px;
        text-align: center;
      }
    }

    .item_list {
      position: relative;
      margin-right: 15px;
    }

    .close_item {
      position: absolute;
      left: 40px;
      z-index: 220;
      top: -3px;
    }
  }
}

.fix-bottom-bar {
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 16px #0076ec1a;
  opacity: 1;

  .cancel-btn {
    text-decoration: none;
    color: #4f9fe6;
    background-color: #ecf5fd;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 10px;
    border: none;
  }

  .continue-btn {
    text-decoration: none;
    color: #ffffff;
    border: none;
    background-color: #4f9fe6;
    border-radius: 5px;
    padding: 7px 15px;
    margin-right: 20px;
  }
}

.owner-details {
  margin-bottom: 80px;
}
