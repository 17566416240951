// ----------------------------------------------------------------------
// SCSS APPLAY Component - TransportVehiclelist & VehicleList & DriverList
// ----------------------------------------------------------------------

.vehicle {
  height: 100vh;
  background-color: #f0f9fc;
  margin: 20px 20px 20px 90px;
  transition: all 0.5s ease-in-out;

  .searchBox {
    position: relative;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .searchText {
    border-radius: 20px;
    border: none;
    outline: none;
    height: 40px;
    transition: all 0.2s linear;
  }
  .searchText:focus {
  }
  .fa-search {
    position: absolute;
    display: grid;
    place-content: center;
    right: 7px;
    margin-top: -11px;
    height: 20px;
  }

  .search-icon {
    position: relative;
    text-align: center;
    cursor: pointer;
    right: 25px;
    top: 49px;
    width: 25px;
    height: 25px;
  }
}

.PageSelect {
  //   padding-left: 31px;
  .EntPage {
    color: #2c3244;
    margin-right: 7px;
  }
  .form-select {
    width: 100%;
  }
}

// Right Side Icons

.icons {
  width: 40px;
  height: 40px;
  padding-top: 10px;
  text-align: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 16px #00000007;
border-radius: 5px;
opacity: 1;
}
.icons:hover {
  width: 40px;
  height: 40px;
  background-color: #f0f9fc;
  padding-top: 10px;
  width: 40px;
  text-align: center;
  .add:active {
    width: 40px;
    height: 30px;
  }
}

.emptyVehicle {
  position: relative;
  top: 140px;
  left: 550px;
}

//  Media Query

@media (max-width: 991px) {
  .PageSelect {
    margin-top: 5px;
  }
  .searchBox {
  }
  .emptyVehicle {
    position: relative;
    top: 100px;
    left: 290px;
  }
  .VehicleTable {
  }
}

//  -##---------------------------------##
//        VehicleList scss
// -##---------------------------------##

.TableList {
  border: 5px;
  // overflow-y: scroll;
  // height: 150px;
  position: relative;
}
.Rowbgcolor {
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 16px #00000007;
border-radius: 5px;
opacity: 1;
}

#optionclick {
  float: right;
  margin-right: 20px;
}
thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}


// Table Media-Query

@media screen and (max-width: 768px),
  (min-device-width: 768px) and (max-device-width: 992px) {
 
}

// --------------------
// order Confirmation


// Table Media-Query

@media screen and (max-width: 768px),
  (min-device-width: 768px) and (max-device-width: 992px) {

}

//------------------------------------------
// Table Order Confirmation 1
//-------------------------------------------



// Table Media-Query

@media screen and (max-width: 768px),
  (min-device-width: 768px) and (max-device-width: 992px) {
  
}

thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}

// Drop-Dwon click
.orderConArow {
  position: absolute;
  left: 135px;
  bottom: -3px;
}

.option-dropdown {
  text-align: right;

  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 95px;
  }

  ul {
    margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739b;
  }
}

//  pagination start
.Vehiclelistpage {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 250px;
  height: 40px;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 8px;
  padding-left: 10px;
  text-align: center;

  .R-arow {
    border-right: 2px solid #edf4fc;
    // display: flex;
    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
  .L-arow {
    border-left: 2px solid #edf4fc;

    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
}

.Vehiclelistpage {
  display: inline-block;
}

.Vehiclelistpage a {
  color: #b2bad3;
  float: left;
  padding: 8px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.Vehiclelistpage a:hover {
  color: #2f6b80;
}

// -------------------------
// Driving List Edit Option
// -------------------------
.editclick {
  margin-left: 910px;
  width: 140px;
  height: 120px;
  text-align: left;
  list-style: none;
  text-decoration: none !important;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 3px 2px 5px #00000019;
  .option {
    position: relative;
  }
  .righttoggle {
    margin-left: 84px;
    margin-top: -17px;
    position: absolute;
  }
  .edit {
    text-decoration: none;
    color: #66739b;
    .edit {
      text-decoration: none;
    }
    text-decoration: none !important;
  }
}

.aLink {
  text-decoration: none;
}
.option:hover {
  background-color: #f5f8fd;
  text-decoration: none;
}

// ##------------------------------------------##
//     Media Querry [Desktop / Laptop / Modbile]
// ##-------------------------------------------##

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .vehilelist {
    width: 700px !important;
  }
  .Edit {
    margin-left: 550px;
    margin-top: 250px;
  }
  #selectRange {
    margin-top: 200px;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  .vehilelist {
    width: 600px !important;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
