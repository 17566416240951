body {
  font-family: "Overpass", sans-serif;
}

.section-body {
  height: 100vh;
  background-color: #F0F9FC;
  padding: 0px;
  transition: all 0.5s ease-in-out;

}

a {
  cursor: pointer;
}

#card-menu {
  margin-left: 30px;
}

.card {
  
  padding: 10px;
  background: #FDFEFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #00000007;
  border-radius: 15px;
  opacity: 1;
  border: none;
}

.cards {
  transition: all 0.5s;
  // margin: 20px;
  display: flex;

}

#cards {
  padding-bottom: 40px;
  padding-right: 23px;
}

.ic_options {
  margin-left: 40px;
}

#option {
  padding-left: 34px !important;
}

.icoption {
  margin-left: 210px;

}



// p {
//   color: #66739b;
//   margin-left: 2px;
// }

#offerlist {
  a {
    padding: 10px;
    text-decoration: none;
    color: #66739b;
  }

  a:hover {
    color: #4f9fe6;
  }
}

.btnVehicle {

  // margin-left: 10px;
  #btnre {
    background-color: #ecf5fd;
    width: 100px;
    color: #4f9fe6;
    margin: 5px;
    border-radius: 5px;
    opacity: 1;
  }

  #btnre:hover {
    color: #ffffff;
    background-color: #4f9fe6;
  }

  #bids {
    background-color: #ecf5fd;
    color: #4f9fe6;
    margin: 5px;
    border-radius: 5px;
    opacity: 1;
  }

  #bids:hover {
    color: #ffffff;
    background-color: #4f9fe6;
  }
}


#approval {
  color: #4f9fe6;
  margin-top: 15px;
  padding-bottom: 10px;

}

#approval:hover {
  color: #33778E;
}

.viewdetails {
  position: absolute;
  margin-left: 80px;
  width: 140px;
  height: 80px;
  background-color: #FFFFFF;
  border-radius: 15px;
  justify-content: center;
  padding-top: 10px;

  padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  span {
    color: #66739B;

  }

  .aLink:hover {
    background-color: #F5F8FD;
    border-radius: 5px;

  }
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;

}

.freights {
  position: absolute;
  margin-left: 30px;
  width: 190px;
  height: 240px;
  background-color: #FFFFFF;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;

  // padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  .option {
    padding: 10px;
    padding-left: 0px;
    border-radius: 7px;
    text-align: left;
  }

  span {
    color: #66739B;

  }

  // .aLink:hover{
  //   background-color: #F5F8FD;
  //   border-radius: 5px;

  // }
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;

}

.icon-dropdown {
  text-align: right;
  // margin-right: 20px;

  .ArowReicev {
    position: absolute;
    left: 136px;
    bottom: 30px;

  }

  ul {
    margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739B;
  }

}



// -------------------------------------------
// Media Query :start
// --------------------------------------------

@media (max-width: 576px) {
  .cards {
    flex-direction: column;
    justify-content: center;
    // padding: 12px;
  }

  #card-menu {
    margin-left: 0px;
  }

  #option {
    flex-direction: row;
    padding-left: 0px;

    .ic_ s {
      margin-left: 0px !important;
    }
  }

}