@import "../css//variables.scss";
@import "../css/mixin.scss";


.vehicle-list {
  @include main-body;
  margin-left: 90px;
  background-color: #f0f9fc;

  .searchBox {
    position: relative;
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .searchText {
    border-radius: 20px;
    border: none;
    outline: none;
    height: 40px;
    transition: all 0.2s linear;
  }
  .searchText:focus {
  }
  .fa-search {
    position: absolute;
    right: 7px;
    margin-top: -11px;
    width: 30px;
    height: 20px;
  }

  .search-icon {
    position: relative;
    text-align: center;
    cursor: pointer;
    right: 25px;
    top: 49px;
    width: 25px;
    height: 25px;
  }
}

.PageSelect {
  //   padding-left: 31px;
  .EntPage {
    color: #2c3244;
    margin-right: 7px;
  }
  .form-select {
    width: 100%;
  }
}

// Right Side Icons

.icons {
  width: 40px;
  height: 40px;
  background-color: white;
  padding-top: 10px;
  text-align: center;
}
.icons:hover {
  width: 40px;
  height: 40px;
  background-color: #f0f9fc;
  padding-top: 10px;
  width: 40px;
  text-align: center;
  .add:active {
    width: 40px;
    height: 30px;
  }
}
.icon-tooltip{
  padding: 0 10px;
  visibility: hidden;
  position: absolute;
  top: 140;
  bottom: 0;
  margin: auto;

  right: 0px;
  width: auto;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  boxxxx-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
  border-radius: 5px;
  opacity: 0;
  z-index: 1001;
}
.icon-tooltip::after{
  content:'';
  position:absolute;
  top:100;
  // left:-7px;

  margin: auto;
 
}
li:hover .icon-tooltip{
  opacity:1;
  visibility: visible;
  top: 100;
}



.tableMargin{
  margin-bottom: 100px;
}





.emptyVehicle {
  position: relative;
  top: 140px;
  left: 550px;
}

//  Media Query

@media (max-width: 991px) {
  .searchBox {
    margin-top: 5px;
  }
  .emptyVehicle {
    position: relative;
    top: 100px;
    left: 290px;
  }
  .VehicleTable {
    overflow-y: scroll;
  }
}

//  -##---------------------------------##
//        VehicleList scss
// -##---------------------------------##
.table-row{
    border-bottom:1px solid #2f6b80;
  background-color: #ffffff;
}
.Rowbgcolor {
  // border-bottom: 3px solid #f0f9fc;
  margin: 10px;
}

#optionclick {
  float: right;
  margin-right: 20px;
}
thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}



// .table-container {
//   display: flex;
//   justify-content: center;
//   margin: 10vh auto;

//   padding: 5px;
//   table {
//     width: 90%;
//     box-shadow: 15px 15px 5px 6px #f0f9fc;
//     border-collapse: collapse;
//   }
//   tr:nth-of-type(odd) {
//     color: #2c3244;
//   }
//   tr:nth-of-type(even) {
//     color: #2c3244;
//   }
//   th {
//     text-align: center;
//     font: normal normal 600 14px/17px Overpass;
//     letter-spacing: 0.19px;
//     color: #66739b;
//     opacity: 1;

//   }
//   td {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 8px 14px #00000007;
//     border-radius: 5px;
//     opacity: 1;
//     text-align: center;
//     font: normal normal normal 12px/22px Overpass;
//     letter-spacing: 0.22px;
//     color: #2c3244;
//     padding: 8px;
 
//   }
// }

// Table Media-Query

@media screen and (max-width: 425px),
  (min-device-width: 425px) and (max-device-width: 992px) {
  // .table-container {
  //   width: 95%;
  //   background: transparent;
  //   padding-left: none;
  //   margin-bottom: 120px !important;
  //   height: 100vh;
  // }
  // table,
  // thead,
  // tbody,
  // th,
  // td,
  // tr {
  //   display: block;
  // }
  // thead tr {
  //   position: absolute;
  //   top: -9999px;
  //   left: -9999px;
  // }
  //  th{
  //    text-align: left;
  //  }
   


  // tr {
  //   border: 1px solid #ccc;
  //   margin-bottom: 5px;
  //   background: #ffffff 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 8px 14px #00000007;
  //   border-radius: 5px;
  //   opacity: 1;
  // }
  // td {
  //   border: none;
  //   border-bottom: 1px solid #00000007;
  //   position: relative;
  //   padding-left: 50%;
  //   text-align: right !important;
  // }
  // td:before {
  //   position: absolute;
  //   top: 6px;
  //   left: 6px;
  //   width: 45%;
  //   padding-right: 26px;
  //   white-space: nowrap;
  //   font-size: 10px;
  //   font-weight: 600;
  //   text-align: left;
  // }
  // td:nth-of-type(1)::before {
  //   content: "S.No";
  // }
  // #Owner:nth-of-type(2)::before {
  //   content: "Owner Name";
  // }
  // #Vehicle:nth-of-type(3)::before {
  //   content: "Vehicle Type";
  // }
  // #Model:nth-of-type(4)::before {
  //   content: "Model";
  // }
  // #Plate:nth-of-type(5)::before {
  //   content: "Plate No.";
  // }
  // #DriverNAM:nth-of-type(6)::before {
  //   content: "Driver Name";
  // }
  // #Nationality:nth-of-type(7)::before {
  //   content: "Nationality";
  // }
  // #Owner:nth-of-type(2)::before {
  //   content: "Owner Name";
  // }
  // #Vehicle:nth-of-type(3)::before {
  //   content: "Vehicle Type";
  // }
  // #Model:nth-of-type(4)::before {
  //   content: "Model";
  // }
  // #Plate:nth-of-type(5)::before {
  //   content: "Plate No.";
  // }
  // #DriverNAM:nth-of-type(6)::before {
  //   content: "Driver Name";
  // }
  // #Nationality:nth-of-type(7)::before {
  //   content: "Nationality";
  // }
}

thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}

// ------------------------------------
//  SelectVehiclelist

// .table_selectVehicle {
//   display: flex;
//   justify-content: center;
//   margin: 10vh auto;
//   overflow-y: auto;
//   padding: 5px;
//   table {
//     width: 90%;
//     box-shadow: 15px 15px 5px 6px #f0f9fc;
//     border-collapse: collapse;
//   }
//   tr:nth-of-type(odd) {
//     color: #2c3244;
//   }
//   tr:nth-of-type(even) {
//     color: #2c3244;
//   }
//   th {
//     text-align: left;
//     font: normal normal 600 14px/17px Overpass;
//     letter-spacing: 0.19px;
//     color: #66739b;
//     opacity: 1;
//   }
//   td {
//     background: #ffffff 0% 0% no-repeat padding-box;
//     box-shadow: 0px 8px 16px #00000007;
//     border-radius: 5px;
//     opacity: 1;
//   }
// }

// Table Media-Query

@media screen and (max-width: 425px),
  (min-device-width: 425px) and (max-device-width: 992px) {
  // .table_selectVehicle {
  //   width: 95%;
  //   background: transparent;

  //   height: 100vh;
  // }
  // table,
  // thead,
  // tbody,
  // th,
  // td,
  // tr {
  //   display: block;
  // }
  // thead tr {
  //   position: absolute;
  //   top: -9999px;
  //   left: -9999px;
  // }
  // tr {
  //   border: 1px solid #ccc;
  //   margin-bottom: 5px;
  //   background: #ffffff 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 8px 16px #00000007;
  //   border-radius: 5px;
  //   opacity: 1;
  // }
  // td {
  //   border: none;
  //   border-bottom: 1px solid #00000007;
  //   position: relative;
  //   padding-left: 50%;
  //   text-align: right;
  // }
  // td:before {
  //   position: absolute;
  //   top: 6px;
  //   left: 6px;
  //   width: 45%;
  //   padding-right: 26px;
  //   white-space: nowrap;
  //   font-size: 10px;
  //   font-weight: 600;
  //   text-align: left;
  // }
  // td:nth-of-type(1)::before {
  //   content: "S.No";
  // }
  // #Owner:nth-of-type(2)::before {
  //   content: "Owner Name";
  // }
  // #Vehicle:nth-of-type(3)::before {
  //   content: "Vehicle Type";
  // }
  // #Model:nth-of-type(4)::before {
  //   content: "Model";
  // }
  // #Plate:nth-of-type(5)::before {
  //   content: "Plate No.";
  // }
  // #DriverNAM:nth-of-type(6)::before {
  //   content: "Driver Name";
  // }
  // #Nationality:nth-of-type(7)::before {
  //   content: "Nationality";
  // }
}

thead {
  color: #66739b;
}

.tbody {
  color: #2c3244;
  border-radius: 5px;
}

// Drop-Dwon click

.option-dropdown {
  text-align: right;
 
  .arrowvehicle {
    position: absolute;
    left: 135px;
    bottom: 95px;
  }

  ul {
    margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739b;
  }
}

//  pagination start
.Vehiclelistpage {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 250px;
  height: 40px;
  background-color: #ffffff;
  justify-content: center;
  border-radius: 8px;
  padding-left: 10px;
  text-align: center;

  .R-arow {
    border-right: 2px solid #edf4fc;
    // display: flex;
    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
  .L-arow {
    border-left: 2px solid #edf4fc;

    img {
      margin-top: 5px;
      width: 12px;
      height: 15px;
    }
  }
}

.Vehiclelistpage {
  display: inline-block;
}

.Vehiclelistpage a {
  color: #b2bad3;
  float: left;
  padding: 8px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.Vehiclelistpage a:hover {
  color: #2f6b80;
}

// -------------------------
// Driving List Edit Option
// -------------------------
.editclick {
  margin-left: 910px;
  // margin-top: 170px;
  // position: relative;
  width: 140px;
  height: 120px;
  // padding-top: -25px !important;
  // z-index: 10222;
  // transform: translate(-45px, 0px, 0px) !important;
  text-align: left;
  list-style: none;
  text-decoration: none !important;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 3px 2px 5px #00000019;
  .option {
    position: relative;
  }
  .righttoggle {
    margin-left: 84px;
    margin-top: -17px;
    position: absolute;
  }
  .edit {
    text-decoration: none;
    color: #66739b;
    .edit {
      text-decoration: none;
    }
    text-decoration: none !important;
  }
}

.aLink {
  text-decoration: none;
}
.option:hover {
  background-color: #f5f8fd;
  text-decoration: none;
}

// ##------------------------------------------##
//     Media Querry [Desktop / Laptop / Modbile]
// ##-------------------------------------------##

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .vehilelist {
    width: 700px !important;
  }
  .Edit {
    margin-left: 550px;
    margin-top: 250px;
  }
  #selectRange {
    margin-top: 200px;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  .vehilelist {
    width: 600px !important;
  }

  .pagination {
    margin-top: 200px;
    margin-left: 500px;
  }
}
