@import '../css/mixin.scss';
@import '../css/variables.scss';


.header-body {
    .tab-content {
        padding:  0 20px;
    }

    li {
        margin-top: 8px;
    }

    transition: all 0.5s ease-in-out;
    margin-left: 70px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px #00000008;

    .view-details {
        transition: all 0.5s ease-in-out;

        text-decoration: none;
        margin-left: 15px;
        text-align: left;
        font: normal normal bold 14px/22px Overpass;
        letter-spacing: 0.42px;
        color: #4F9FE6;
        opacity: 1;
    }

}

.tab-link {

    text-align: left;
    font: normal normal bold 14px/22px Overpass;
    letter-spacing: 0.42px;
    color: #B2BAD3;
    opacity: 1;

    &:hover {
        color: #4F9FE6 !important;
    }
}