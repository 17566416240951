.vehicle-request-main{
    margin: 20px 20px 20px 90px;
    transition: all 0.5s;
}

.vehicleRequest-btn-remove{
border: none;
background: #FF000012 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 1;
padding: 5px 10px;
img{
    vertical-align: middle;
}
}

.vehicleRequest-btn{
    background: #ECF5FD 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 1;
border: none;
color: #4F9FE6 !important;
padding: 5px 20px;

}

.ViewSpecifications-link{
    text-decoration: underline;
font: normal normal normal 14px Overpass;
letter-spacing: 0.22px;
color: #4F9FE6 !important;
opacity: 1;
}
.modal-content{
// background: #F0F9FC 0% 0% no-repeat padding-box !important;
border-radius: 15px;
opacity: 1;
max-height: 70vh;
width: 60vw !important;
}
.accept-popup{
    .modal-content{
    background-color: #F0F9FC !important;}
}

.popup-approve-btn {
    text-align: center;
    font: normal normal 600 16px ;
    letter-spacing: 0.38px;
    color: #4F9FE6;
    border:1px solid #0DA1E158;
    border-radius: 5px;
    opacity: 1;
    padding: 5px 15px;
    background: transparent;
    &:hover{
        transition: all ease-out 0.3s;
        background: #4F9FE619 0% 0% no-repeat padding-box;
        border:1px solid #4F9FE619; 
       }
}

.popup-reject-btn {
    text-align: center;
    font: normal normal 600 16px;
    letter-spacing: 0.38px;
    color: #4F9FE6;
    border: 1px solid #0DA1E158;
    border-radius: 5px;
    background: transparent;
    opacity: 1;
    padding: 5px 15px;

    &:hover{
        transition: all ease-out 0.3s;
        background: #4F9FE619 0% 0% no-repeat padding-box;
        border: 1px solid #4F9FE619;
    }
}