body {
  font-family: "Overpass", sans-serif;
}
.section {
  z-index: 1000;
 
    margin: 20px 20px 20px 70px;
  
  height: 100vh;

  background-color: #f0f9fc;
  margin: 0px;
  padding: 0px;
}


  #btnre {
    background-color: #ecf5fd;
    width: 100px;
    color: #4f9fe6;
    margin: 5px;
    border-radius: 5px;
    opacity: 1;
    border: none;
  }

  #btnre:hover {
    color: #ffffff;
    background-color: #4f9fe6;
  }
  #bids {
    background-color: #ecf5fd;
    color: #4f9fe6;
    margin: 5px;
    border-radius: 5px;
    opacity: 1;
  }
  #bids:hover {
    color: #ffffff;
    background-color: #4f9fe6;
  }


#approval {
  color: #4f9fe6;
  margin-top: 15px;
  padding-bottom: 10px;
}
#approval:hover {
  color: #33778e;
}

.viewdetails {
  position: absolute;
  margin-left: 80px;
  width: 140px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding-top: 10px;

  padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  span {
    color: #66739b;
  }
  .aLink:hover {
    background-color: #f5f8fd;
    border-radius: 5px;
  }
}
.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}
.freights {
  position: absolute;
  margin-left: 30px;
  width: 190px;
  height: 240px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;

  // padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;
  .option {
    padding: 10px;
    padding-left: 0px;
    border-radius: 7px;
    text-align: left;
  }
}
dd {
  text-align: left;
  font: normal normal normal 14px/20px Overpass;
  letter-spacing: 0.42px;
  color: #66739b;
  opacity: 1;
}
.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}
.ongoing-dropdown {
  text-align: right;
  // margin-right: 130px;

  .ongoingarrow {
    position: absolute;
    left: 136px;
    bottom: 90px;
  }
  .dropdown-menu.show {
    margin-left: -118px !important;
  }

  ul {
    // margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739b;
    margin-right: 30px;
  }
}

.my-free {
  text-align: left;
  font: normal normal normal 16px/23px Overpass;
  letter-spacing: 0.48px;
  color: #2c3244;
  opacity: 1;
}


.requestarow {
  position: absolute;
  left: 136px;
  bottom: 36px;
}
.OfferVehicle {
  justify-content: right;
  text-align: right;
  text-align: left;
  

}

.bydefult {
  font: normal normal normal 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #66739b;
  opacity: 1;
  width:100%;
  height: 100%;
  border: none;
  background-color:#ffffff ;
  box-shadow: 0px 4px 16px #00000008;
  border-radius: 7px;

}
.modal-content {
    padding: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #2614070D;
    border-radius: 15px;
    opacity: 1;
    // width: 400px !important;
    border: none;
    width: 100vw;
  }


  @media(max-width:768px) {
    .modal-content {
      width:95% !important;
    }
  }













