.main-offerVehicles {
  margin: 20px 20px 20px 90px;
  transition: all 0.5s;
}

.btn-offer-vehicle {
  border: none;
  background: #4F9FE6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 600 16px;
  letter-spacing: 0.38px;
  color: #FFFFFF;
  padding: 5px 20px;
}

.fix-offer-vehicles {
  
  margin-left: -20px;
  position: fixed;
  bottom: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px -4px 16px #0076EC1A;
  opacity: 1;
  height: 140px;
  width: 100%;
}

.modal-content {
  background: #FDFEFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #00000007;
  border-radius: 15px;
  opacity: 1;
}

.modal-fullscreen .modal-content {
  height: 100vh !important;
  width: 100vw !important;
  border: 0;
  border-radius: 0;
}

.modal-content {
  max-height: 100vh !important;
}

.border-block {
  border: 1px dashed #DDDDDD;
  border-radius: 10px;
  opacity: 1;

  .uploading {
    padding: 40px;
    text-align: center;
  }
}

.close_item {
  position: absolute;
  left: 40px;
  z-index: 220;
  top: -3px;

}

.item_list {
  position: relative;
  margin-right: 15px;
}
.modal-content {
  .popup-offer-vehicle {
    background-color: #FFFFFF !important;
  }
}