@import "../css//variables.scss";
@import "../css/mixin.scss";

.main-section-reports {
  .nav-pills .nav-link {
    border-radius: 0 !important;
  }
  position: relative;
  transition: all 0.5s;
  margin: 0 0 0 70px;
  overflow-x: hidden;
  .reports-tab {
    flex-basis: 30%;
  }

  .tab-content {
    position: relative;
    flex-basis: 70%;
  }

  .nav-pills .nav-link.active {
    color: #45aeea;
    background-color: #EAF6FC;

    .arrow-orange {
      position: absolute;
      right: -28px;
      top: 5px;
    }
  }

  .nav-pills .nav-link {
    color: black;
    text-align: start;
    padding: 20px;
    border-bottom: 1px solid #f6f2ef;
  }

  .nav-pills .nav-link.active:after {
    position: relative;
  }

  .reports-body-section {
    @include common-body;
    position: absolute;
    left: 50%;
    width: 400px;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 280px;
    height: 80vh;
  }

  .reports-card-heading {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.43px;
    color: #1f1104;
  }

  .report-btn {
    margin: 0 20px;
    width: -webkit-fill-available;
    position: absolute;
    bottom: 20px;
    left: 0;
    display: inline-block;
    text-decoration: none;
    background-color: #45aeea;
    color: $btn-font-color2;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font: normal normal 600 16px/19px Barlow;
    letter-spacing: 0.38px;
    opacity: 1;
  }
}

.reports-tab {
  background-color: #ffff;
  // width: 30%;
  height: auto;
  min-height: 100vh;
}

.tab-heading-reports {
  font: normal normal 600 16px/20px Overpass;
  letter-spacing: 0.38px;
  color: #45aeea;
  text-transform: uppercase;
  padding: 15px;
}
.main-assetsReports {
  cursor: pointer;
  padding: 20px;
  background: #edf4f4 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
}
.assetsReports {
  font-size: 16px !important;
  letter-spacing: 0.38px;
  color: #191a1a !important;
}
.arrow-orange {
  position: absolute;
  right: -28px;
  top: 5px;
}
