@import "../css//variables.scss";
@import "../css/mixin.scss";

.login-main-section {
  .left-section {
    height: 100vh;
    // background-image: url("../images/login_bg.svg");
    background-color: #45AEEA;

    .left-content {
      text-align: center;
    max-width: 600px;
    color: #ffffff;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }

    .left-login-paragraph {
      text-align: center;
      font: normal normal normal 16px/24px Overpass;
      letter-spacing: 0.38px;
      color: #FFFFFF;
      opacity: 0.75;
      padding: 0 20px;
    }

    .heading {
      font: normal normal bold 30px/46px Overpass;
      letter-spacing: 0.72px;
      color: #FFFFFF;
      opacity: 1;

    }
  }

  .right-section {

    height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    .right-content {
      flex-basis: 80%;
      max-width: 600px;
    }

    h3 {
      text-align: left;
      font: normal normal bold 30px Overpass;
      letter-spacing: 0.72px;
      color: #2C3244;
      opacity: 1;
    }


    .login-btn {
      margin-top: 50px;

      a {
        text-decoration: none;
        background-color: #1B9BE6;
        color: #ffffff;
        padding: 5px;
        display: inline-block;
        text-align: center;
        border-radius: 8px;
        font-size: 18px;
      }
    }

    .forgot {
      text-align: right;

      a {
        text-decoration: none;
        color: #767B7A;
      }
    }

    p {
      font-size: 16px;
      text-align: left;
      letter-spacing: 0.38px;
      color: #767B7A;
      font-weight: 600;

      span {
        color: #1B9BE6;
        font-weight: 600;
        letter-spacing: 0.38px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1010px) {
  .right-section {
    padding: 20px !important;

    .login-btn {
      display: grid;
    }
  }
}